import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Section from "../../elements/Section/index"
import Container from "../../elements/Container/index"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.isAlignLeft ? "flex-start" : "center")};
  width: 100%;
  height: 100%;
`

const Title = styled.h1`
  color: ${props => props.theme.white};
  font-family: Rajdhani;
  font-size: 28px;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: bold;
  text-align: ${props => (props.isAlignLeft ? "left" : "center")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "4rem")};
  max-width: 900px;

  @media (min-width: ${globalVariables.minTablet}) {
    font-size: ${props => (props.fontSize ? props.fontSize : "34px")};
    line-height: ${props => (props.isAlignLeft ? "1.1" : "normal")};
    max-width: ${props => (props.isAlignLeft ? "600px" : "900px")};
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    font-size: 44px;
  }
`

const Banner = ({
  title,
  imageBanner,
  imageHeight,
  imageMobileHeight,
  buttonText,
  buttonUrl,
  isfirstBlock,
  marginBottom,
  fontSize,
  isAlignLeft,
  objectPosition,
  colorsButton,
}) => {
  return (
    <Section
      padding="0"
      isfirstBlock={isfirstBlock}
      imageHeight={imageHeight}
      imageMobileHeight={imageMobileHeight}
      objectPosition={objectPosition}
    >
      <Img fluid={imageBanner} />
      <Wrapper>
        <Container>
          <Content isAlignLeft={isAlignLeft}>
            <Title
              marginBottom={marginBottom}
              fontSize={fontSize}
              isAlignLeft={isAlignLeft}
            >
              {title}
            </Title>

            {buttonText && buttonUrl && (
              <Button
                title={buttonText}
                as="link"
                to={buttonUrl}
                colors={colorsButton ? colorsButton : "secondaryWithBg"}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default Banner
